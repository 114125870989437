<template>
    <div>
        <vx-card title="Account Receivable Clearing Gov (Adjustment)">
            <vs-tabs :color="colorx" v-model="tabs">
                <vs-tab @click="colorx = 'danger'" label="Open">
                    <div class="con-tab-ejemplo">
                        <open/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'warning'" label="Confirmed">
                    <div class="con-tab-ejemplo">
                        <confirm/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Canceled">
                    <div class="con-tab-ejemplo">
                        <cancel />
                    </div>
                </vs-tab>
            </vs-tabs>
        </vx-card>
    </div>
</template>
<script>
import open from "./open/open.vue";
import confirm from "./confirm/confirm.vue";
import cancel from "./cancel/cancel.vue";
// import done from "./done.vue";

export default {
    components: {
        open,
        confirm,
        cancel,
    },
    data:() => ({
        colorx: "danger",
        tabs: 0,
    })
}
</script>